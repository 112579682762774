/*
 * @Descripttion: 首页模块接口
 * @Author: Dyf
 * @Date: 2023-04-12
 * @LastEditors: Dyf
 * @LastEditTime: 2023-04-14
 */
import HTTP from "@utils/http";
import { PAGE_SIZE } from "@config";

const plastucurriculum = "/studentpc/plastucurriculum/"; //  课程
const stuexamination = "/studentpc/stuexamination/"; // 考试
const stuuser = "/studentpc/stuuser/"; //学生班级学期
const stuwrongquestionscschid = "/studentpc/stuwrongquestionscschid/"; //错题主列表
const stuquestionscschid = "/studentpc/stuquestionscschid/"; // 错题详情
const plastumessage = "/studentpc/plastumessage/"; // 首页
const plastrictcurriculum = "/studentpc/plastrictcurriculum/"; // 课程
const stuhome = "/studentpc/stuhome/"; // 作业
const stuhomework = "/studentpc/stuhomework/"; // 答题拍照


//课程分类-启用中
export async function plastucurriculumclassList(prams) {
  try {
  return await HTTP.get("/studentpc/plastucurriculumclass/list", prams);
  } catch (error) {
  return error;
  }
}

// 课程列表
export async function plastucurriculumList(prams) {
  try {
  return await HTTP.post(plastucurriculum+"list", prams);
  } catch (error) {
  return error;
  }
}
// 课程详情
export async function plastucurriculumInfo(prams) {
  try {
  return await HTTP.post(plastucurriculum+"info", prams);
  } catch (error) {
  return error;
  }
}
// 收藏课程
export async function plastucurriculumCollect_courses(prams) {
  try {
  return await HTTP.post(plastucurriculum+"collect_courses", prams);
  } catch (error) {
  return error;
  }
}
// 报名课程
export async function plastucurriculumEnroll_courses(prams) {
  try {
  return await HTTP.post(plastucurriculum+"enroll_courses", prams);
  } catch (error) {
  return error;
  }
}
// 考试列表
export async function stuexamination_list(prams) {
  try {
  return await HTTP.post(stuexamination+"list", prams);
  } catch (error) {
  return error;
  }
}
// 试卷详情
export async function stuexamination_info(prams) {
  try {
  return await HTTP.post(stuexamination+"info", prams);
  } catch (error) {
  return error;
  }
}
// 手动标识题目状态
export async function update_question(prams) {
  try {
  return await HTTP.post(stuexamination+"update_question", prams);
  } catch (error) {
  return error;
  }
}
// 开始答题
export async function stuexamination_start_answer(prams) {
  try {
  return await HTTP.post(stuexamination+"start_answer", prams);
  } catch (error) {
  return error;
  }
}
// 学生答题
export async function stuexamination_do_answer(prams) {
  try {
  return await HTTP.post(stuexamination+"do_answer", prams);
  } catch (error) {
  return error;
  }
}
// 试卷提交
export async function stuexamination_submit_paper(prams) {
  try {
  return await HTTP.post(stuexamination+"submit_paper", prams);
  } catch (error) {
  return error;
  }
}
// 上传试题
export async function stuexamination_submit_images(prams) {
  try {
  return await HTTP.post(stuexamination+"submit_images", prams);
  } catch (error) {
  return error;
  }
}

// 学生班级学期
export async function grade_semester(prams) {
  try {
  return await HTTP.post(stuuser+"grade_semester", prams);
  } catch (error) {
  return error;
  }
}

// 学生班级学期
export async function stuwrongquestionscschidList(prams) {
  try {
  return await HTTP.post(stuwrongquestionscschid+"list", prams);
  } catch (error) {
  return error;
  }
}

// 错题详情列表
export async function stuquestionscschidList(prams) {
  try {
  return await HTTP.post(stuquestionscschid+"list", prams);
  } catch (error) {
  return error;
  }
}

// 错题详情
export async function stuquestionscschidInfo(prams) {
  try {
  return await HTTP.post(stuquestionscschid+"info", prams);
  } catch (error) {
  return error;
  }
}

// 随机重做
export async function random_question(prams) {
  try {
  return await HTTP.post(stuquestionscschid+"random_question", prams);
  } catch (error) {
  return error;
  }
}

// 错题提交
export async function submit_question(prams) {
  try {
  return await HTTP.post(stuquestionscschid+"submit_question", prams);
  } catch (error) {
  return error;
  }
}

// 主观题学生标识正确/错误
export async function stu_exactness(prams) {
  try {
  return await HTTP.post(stuquestionscschid+"stu_exactness", prams);
  } catch (error) {
  return error;
  }
}

//  主观题学生标识掌握/未掌握 
export async function is_grasp(prams) {
  try {
  return await HTTP.post(stuquestionscschid+"is_grasp", prams);
  } catch (error) {
  return error;
  }
}
//  首页轮播通知消息 
export async function get_home_notice(prams) {
  try {
  return await HTTP.post(plastumessage+"get_home_notice", prams);
  } catch (error) {
  return error;
  }
}
//  公告列表 
export async function get_home_noticelist(prams) {
  try {
  return await HTTP.post(plastumessage+"get_home_noticelist", prams);
  } catch (error) {
  return error;
  }
}
//  消息详情 
export async function plastumessage_info(prams) {
  try {
  return await HTTP.post(plastumessage+"info", prams);
  } catch (error) {
  return error;
  }
}

//  课程列表 
export async function plastrictcurriculumList(prams) {
  try {
  return await HTTP.post(plastrictcurriculum+"list", prams);
  } catch (error) {
  return error;
  }
}

//  banner 
export async function get_banner_list(prams) {
  try {
  return await HTTP.post(plastrictcurriculum+"get_banner_list", prams);
  } catch (error) {
  return error;
  }
}

//  课程详情 
export async function plastrictcurriculumInfo(prams) {
  try {
  return await HTTP.post(plastrictcurriculum+"info", prams);
  } catch (error) {
  return error;
  }
}
//  增加课程下载量 
export async function add_down(prams) {
  try {
  return await HTTP.post(plastrictcurriculum+"add_down", prams);
  } catch (error) {
  return error;
  }
}
//  答题拍照上传 
export async function photo_answers(prams) {
  try {
  return await HTTP.post(stuhomework+"photo_answers", prams);
  } catch (error) {
  return error;
  }
}
//  答题拍照上传 
export async function do_answers(prams) {
  try {
  return await HTTP.post(stuhomework+"do_answers", prams);
  } catch (error) {
  return error;
  }
}
//  标记错题到错题库
export async function mark_error_question(prams) {
  try {
  return await HTTP.post(stuhomework+"mark_error_question", prams);
  } catch (error) {
  return error;
  }
}
//  作业列表 
export async function homework_list(prams) {
  try {
  return await HTTP.post(stuhome+"homework_list", prams);
  } catch (error) {
  return error;
  }
}
//  首页今日作业 
export async function home_homework_list(prams) {
  try {
  return await HTTP.post(stuhome+"home_homework_list", prams);
  } catch (error) {
  return error;
  }
}
//  作业详情 
export async function homework_info(prams) {
  try {
  return await HTTP.post(stuhome+"homework_info", prams);
  } catch (error) {
  return error;
  }
}
//  单题详情 
export async function get_question_list(prams) {
  try {
  return await HTTP.post(stuhome+"get_question_list", prams);
  } catch (error) {
  return error;
  }
}
//  课程表信息 
export async function get_stu_claschedule(prams) {
  try {
  return await HTTP.post(stuhome+"get_stu_claschedule", prams);
  } catch (error) {
  return error;
  }
}
//  课程详情 
export async function get_courseware_data(prams) {
  try {
  return await HTTP.post(stuhome+"get_courseware_data", prams);
  } catch (error) {
  return error;
  }
}
//  课程内容 
export async function classschedule_list(prams) {
  try {
  return await HTTP.post(stuhome+"classschedule_list", prams);
  } catch (error) {
  return error;
  }
}
//  练习题详情 
export async function practice_info(prams) {
  try {
  return await HTTP.post(stuhome+"practice_info", prams);
  } catch (error) {
  return error;
  }
}
//  获取课堂知识点 
export async function get_Knowledge(prams) {
  try {
  return await HTTP.post(stuhome+"get_Knowledge", prams);
  } catch (error) {
  return error;
  }
}
//  练习单题详情 
export async function practice_list(prams) {
  try {
  return await HTTP.post(stuhome+"practice_list", prams);
  } catch (error) {
  return error;
  }
}
//  首页弹窗 
export async function renewal_pop(prams) {
  try {
  return await HTTP.post(stuhome+"renewal_pop", prams);
  } catch (error) {
  return error;
  }
}
//  结束作业答题 
export async function end_homework(prams) {
  try {
  return await HTTP.post(stuhomework+"end_homework", prams);
  } catch (error) {
  return error;
  }
}









